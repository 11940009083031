<template>
  <v-container fluid class="px-6 ">

    <v-card>

      <v-card-title class="px-6 card-header white--text">
          <v-icon class="mr-3 white--text">mdi-format-list-bulleted</v-icon> <h3>Request List</h3>
      </v-card-title>

      <v-spacer></v-spacer>
      
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        hide-details
        class="px-6 pt-6"
      ></v-text-field>

      <v-data-table
        :headers="headersList"
        :items="newList"
        :items-per-page="5"
        class="elevation-1 px-6 pt-6 admin_table"
        :search="search"
      >
        <template v-slot:item.dataValidationStatus>
          <v-chip color="green" dark>
            Finish Processing
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <template>
            <v-icon small class="mr-2 blue--text" @click="formRequest(item)"
              >mdi-pencil</v-icon
            >
          </template>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="formApplication" max-width="1000">
      <v-card class="px-8 form">
        <v-card-title class="primary--text"
          >Application Information</v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col cols="3">
              Full Name
            </v-col>
            <v-col cols="1">
              :
            </v-col>

            <v-col cols="6">
              {{ detailForm.name }}
            </v-col>

            <v-col cols="3">
              Ministry / Agency
            </v-col>
            <v-col cols="1">
              :
            </v-col>
            <v-col cols="6">
              {{ detailForm.name }}
            </v-col>

            <v-col cols="3">
              Phone No.
            </v-col>
            <v-col cols="1">
              :
            </v-col>
            <v-col cols="6">
              {{ detailForm.phoneNo }}
            </v-col>

            <v-col cols="3">
              Email Name
            </v-col>
            <v-col cols="1">
              :
            </v-col>
            <v-col cols="6">
              {{ detailForm.email }}
            </v-col>

            <v-col cols="3">
              Designation
            </v-col>
            <v-col cols="1">
              :
            </v-col>
            <v-col cols="6">
              {{ detailForm.designation }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-title class="primary--text">About Data Sharing</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              Full Name
            </v-col>
            <v-col cols="1">
              :
            </v-col>
            <v-col cols="6">
              {{ detailForm.name }}
            </v-col>

            <v-col cols="3">
              Supporting Document
            </v-col>
            <v-col cols="1">
              :
            </v-col>
            <v-col cols="6">
              document.docx
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-title class="primary--text">Water Quality Station</v-card-title>

        <template>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-center"
                    v-for="item in headersList2"
                    :key="item.name"
                  >
                    {{ item.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td>{{ newFormDetails.agency }}</td>
                  <td>{{ newFormDetails.stationID }}</td>
                  <td>{{ newFormDetails.location }}</td>
                  <td>
                    {{ newFormDetails.status }}
                    <!-- <v-select
                                v-model = "formStatus"
                                :items="statusForm"
                                solo
                                dense
                                hide-details
                                class="my-5"
                                ></v-select> -->
                  </td>
                  <td>
                    {{ newFormDetails.expiredDate }}
                    <!-- <td>  <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    item-text="newFormDetails.status"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        solo
                                        dense
                                        hide-details
                                        class="my-5"
                                    >{{newFormDetails.expiredDate}}</v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date"
                                    @input="menu2 = false"
                                    ></v-date-picker>
                                </v-menu> -->
                  </td>
                  <td>
                    {{ newFormDetails.remarks }}
                    <!-- <v-text-field
                                :items="statusForm"
                                solo
                                dense
                                hide-details
                                class="my-5"
                                >{{newFormDetails.remarks}}</v-text-field> -->
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />

          <v-row>
            <v-col class="px-8" cols="9"> </v-col>
            <v-col class="px-2" cols="1">
              <v-btn
                elevation="2"
                color="primary"
                @click="formApplication = false"
                >Close</v-btn
              >
            </v-col>

            <v-col class="px-8" cols="1">
              <v-btn
                elevation="2"
                color="primary"
                @click="formApplication = false"
                >Print</v-btn
              >
            </v-col>
          </v-row>

          <br />
        </template>
      </v-card>
    </v-dialog>
    
  </v-container>
</template>

<script>
import axios from "axios";
import appMixins from "@/mixins/globalMixins";
// -- import something here --

export default {
  data: () => ({
    search: "",
    formApplication: false,
    detailForm: {},
    statusForm: ["Lulus", "Tolak"],
    formStatus: "Lulus",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,

    headersList: [
      {
        text: "Reference No",
        align: "start",
        sortable: false,
        value: "idUser",
      },
      { text: "Email", value: "email" },
      { text: "Request Date & Time", value: "lastPwdChange" },
      { text: "Status", value: "dataValidationStatus" },
      { text: "Approved Date & Time", value: "lastPwdChange" },
      { text: "Form", value: "actions" },
    ],

    headersList2: [
      {
        text: "Agency",
        align: "start",
        sortable: false,
        value: "idUser",
      },
      { text: "Station ID", value: "email" },
      { text: "Location", value: "lastPwdChange" },
      { text: "Status", value: "dataValidationStatus" },
      { text: "Expired Date", value: "actions" },
      { text: "Remarks", value: "dataValidationStatus" },
    ],

    newFormDetails: {
      agency: "JAS",
      stationID: "CMS02",
      location: "SUNGAI PERAK",
      status: "Lulus",
      expiredDate: "07-02-2023",
      remarks: "Contaminated",
    },

    desserts: [
      {
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: "Not Process",
        protein: 4.0,
        iron: 1,
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
        fat: 9.0,
        carbs: "Not Process",
        protein: 4.3,
        iron: 1,
      },
      {
        name: "Eclair",
        calories: 262,
        fat: 16.0,
        carbs: "Not Process",
        protein: 6.0,
        iron: 7,
      },
      {
        name: "Cupcake",
        calories: 305,
        fat: 3.7,
        carbs: "Not Process",
        protein: 4.3,
        iron: 8,
      },
      {
        name: "Gingerbread",
        calories: 356,
        fat: 16.0,
        carbs: "Not Process",
        protein: 3.9,
        iron: 16,
      },
    ],

    newList: [],
  }),

  mounted() {
    console.log("welcome home");

    this.getAllUser();
  },

  methods: {
    getAllUser() {
      axios
        .get(appMixins.data().globalUrl + `/sarawak/users/all`, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.newList = response.data;
          console.log(this.userData);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    formRequest(item) {
      this.formApplication = true;
      console.log(this.formApplication, item);
      this.detailForm = item;
    },
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.background {
  background-color: whitesmoke;
  height: 100%;
  width: 100%;
  top: 0;
  background-size: cover;
  top: 0;
  right: 0;
}

.form {
  // width: 500px;
}
</style>
